require('./bootstrap')

const IMAGES_MAX_SIZE_VALUE = 10485760,
    IMAGES_MAX_SIZE_UNIT = '5MB.'

function previewImage(inputId) {
    $("#" + inputId).change(function () {
        let input = $(this),
            files = input.prop('files')

        if (files && files[0]) {
            if (files[0].size > IMAGES_MAX_SIZE_VALUE) {
                input.val('')
                One.helpers('jq-notify', {type: 'danger', icon: 'fa fa-times me-1', message: 'Tamaño Máximo ' + IMAGES_MAX_SIZE_UNIT})
                $("#placeholder_" + input.attr('id') + " img").attr("src", $(this).data('placeholder'))
                return
            }
            let reader = new FileReader()
            reader.onload = function (e) {
                $("#placeholder_" + input.attr('id') + " img").attr("src", e.target.result)
            }
            reader.readAsDataURL(files[0])
        }
    })
}

$(document).ready(function () {
    /*===================================*
    01. SHOW LOADER ON SUBMIT FORM JS - START
    *===================================*/
    $('form').submit(function () {
        One.loader('show')
    })
    /*===================================*
    01. SHOW LOADER ON SUBMIT FORM JS - END
    *===================================*/

    /*===================================*
    02. INPUT MEDIA INTERACTIVE JS - START
    *===================================*/
    $(".media-import__box").on("click", function (e) {
        e.preventDefault()
        let inputId = $(this).data('input')
        previewImage(inputId)
        $("#" + inputId).click()
    })

    $(".media-import__delete").on("click", function (e) {
        e.preventDefault()
        let inputId = $(this).data('delete'),
            inputRelated = $('#' + inputId)
        inputRelated.val('')
        $("#placeholder_" + inputId + " img").attr("src", inputRelated.data('placeholder'))
    })
    /*===================================*
    02. INPUT MEDIA INTERACTIVE JS - END
    *===================================*/

    /*===================================*
    03. HIGHLIGHT ICON INTERACTIVE JS - START
    *===================================*/
    $('#highlight_icon_one').focusout(e => {
        let icon = $('#highlight_icon_one_prev')
        icon.removeClass()
        icon.addClass('fal fa-' + $(e.currentTarget).val())
    })

    $('#highlight_icon_two').focusout(e => {
        let icon = $('#highlight_icon_two_prev')
        icon.removeClass()
        icon.addClass('fal fa-' + $(e.currentTarget).val())
    })

    $('#highlight_icon_three').focusout(e => {
        let icon = $('#highlight_icon_three_prev')
        icon.removeClass()
        icon.addClass('fal fa-' + $(e.currentTarget).val())
    })

    $('#highlight_icon_four').focusout(e => {
        let icon = $('#highlight_icon_four_prev')
        icon.removeClass()
        icon.addClass('fal fa-' + $(e.currentTarget).val())
    })
    /*===================================*
    03. HIGHLIGHT ICON INTERACTIVE JS - END
    *===================================*/

    /*===================================*
    04. POST NOW JS - START
    *===================================*/
    let postNow = document.getElementById('post_now'),
        releasedAtContainer = document.getElementById('released_at_container'),
        releasedAt = document.getElementById('released_at')

    if (postNow) {
        postNow.addEventListener('change', () => {
            releasedAtContainer.classList.contains('d-none') ? releasedAtContainer.classList.remove('d-none') : releasedAtContainer.classList.add('d-none')
            releasedAt.disabled = postNow.value !== '0'
        })
    }
    /*===================================*
    04. POST NOW JS - END
    *===================================*/
})
